import React, { FC } from 'react';
import { Link } from 'gatsby';
import { useTranslation } from 'react-i18next';

import Seo from '../components/Seo/seo';
import { Layout } from '../components/Layout';
import { StaticImage } from 'gatsby-plugin-image';
import TabPage from '../components/TabPage/TabPageView';
import FaqTabContent from '../components/FaqTabContent/FaqTabContentView';
import useWindowDimensions from '../hooks/useWindowDimensions';

const FaqPage: FC = () => {
    const { t } = useTranslation('translation', { keyPrefix: 'faqPage' });
    const {width} = useWindowDimensions();
    function getBanner(){
        var banner 
        if (width > 768) {
            banner = <StaticImage
            className={'banner'}
                src="../images/banner7.jpg"
                quality={95}
                formats={['auto', 'webp', 'avif']}
                loading='eager'
                alt='Intro banner'
            />
        }
        else{
            banner = <StaticImage
            className={'banner'}
                src="../images/banner7_mobile.jpg"
                quality={95}
                formats={['auto', 'webp', 'avif']}
                loading='eager'
                alt='Intro banner'
            />
        }
        return banner
    }
    const banner = getBanner();
    return (
        <Layout>
            <Seo title={"Vraag & antwoord"} description={"Antwoorden op al jouw vragen."} />
            {banner}
            <div style={{
                    textAlign: 'center',
                    position: 'relative',
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    padding: '1.5rem 0 1.5rem 0',
                    boxShadow: '0 0 10px rgb(72 72 72 / 16%)',
                    zIndex: '200',
                    backgroundColor: '#ffffff',
                    flexDirection: 'column'
                }}>
                    <div className='main_text'>
                        {t('faqIntro')}
                    </div>
                    <div style={{display: 'flex', justifyContent: 'center' , margin: '35px 0 20px'}}>
                        <div className='read_more_btn' style={{ height: '50px', width: '250px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Link style={{ color: '#fff', textDecoration: 'none', width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}} to='/contact/'>{t('faqButton')}</Link>
                        </div>
                    </div>    
                </div>
            <div>
                <TabPage tabNames={[t('tab1_tabname'), t('tab2_tabname'), t('tab3_tabname'), t('tab4_tabname'), t('tab5_tabname')]} tabContent={[
                <FaqTabContent headerList={[t('tab1_subheader1'), t('tab1_subheader2'), t('tab1_subheader3')]} contentList={[t('tab1_content1'), t('tab1_content2')]} mainHeader={t('tab1_mainheader')}/>,
                <FaqTabContent headerList={[t('tab2_subheader1'), t('tab2_subheader2'), t('tab2_subheader3'), t('tab2_subheader4')]} contentList={[t('tab2_content1'), t('tab2_content2'),t('tab2_content3'),t('tab2_content4'),t('tab2_content5'),t('tab2_content6'),]} mainHeader={t('tab2_mainheader')}/>,
                <FaqTabContent headerList={[t('tab3_subheader1'), t('tab3_subheader2'), t('tab3_subheader3')]} contentList={[t('tab3_content1'),t('tab3_content2'),t('tab3_content3'),]} mainHeader={t('tab3_mainheader')}/>,
                <FaqTabContent headerList={[t('tab4_subheader6'),t('tab4_subheader7'),t('tab4_subheader1'), t('tab4_subheader2'), t('tab4_subheader3'),t('tab4_subheader4'), t('tab4_subheader5')]} contentList={[t('tab4_content6'),t('tab4_content7'),t('tab4_content1'),t('tab4_content2'),t('tab4_content3'),t('tab4_content4'),t('tab4_content5')]} mainHeader={t('tab4_mainheader')}/>,
                <FaqTabContent headerList={[t('tab5_subheader1'), t('tab5_subheader2')]} contentList={[t('tab5_content1'),t('tab5_content2'),t('tab5_content3')]} mainHeader={t('tab5_mainheader')}/>,
            ]}/>
            </div>
        </Layout>
    )
}

export default FaqPage;