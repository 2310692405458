import React, { FC } from 'react';

import './FaqTabContent.css';

type FaqTabProps = {
    headerList: String[],
    contentList: String[],
    mainHeader: String

};

const FaqTabContent: FC<FaqTabProps> = ({headerList, contentList, mainHeader}) => {



    return (
        <div style={{padding: '0 30px'}}>
            <h2 className='faq_large_header'>{mainHeader}</h2>
            {contentList.map((value, index) => {
            return <div key={index}>
                <h4 className='faq_small header' key={index}>{headerList[index]}</h4>
                <p className='faq_text_content'>{contentList[index]}</p>
            </div>
                })}
        </div>

        
    );
}

export default FaqTabContent;