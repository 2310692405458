import React, { FC } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import './tab.css';

type TabProps = {
    tabContent: JSX.Element[],
    tabNames: String[];
};


const TabPage: FC<TabProps> = ({tabContent, tabNames}) => {

    return (
        <div>
            <Tabs style={{boxShadow: '0 0 10px rgb(72 72 72 / 16%)'}}>
                <TabList>
                    <Tab>
                        <p className='tab_name'>{tabNames[0]}</p>
                    </Tab>
                    <Tab>
                        <p className='tab_name'>{tabNames[1]}</p>
                    </Tab>
                    <Tab>
                        <p className='tab_name'>{tabNames[2]}</p>
                    </Tab>
                    <Tab>
                        <p className='tab_name'>{tabNames[3]}</p>
                    </Tab>
                    <Tab>
                        <p className='tab_name'>{tabNames[4]}</p>
                    </Tab>
                </TabList>


            
                <TabPanel style={{width: '100%'}}>
                    <div  className="panel-content">
                        {tabContent[0]}
                    </div>
                </TabPanel>
                <TabPanel style={{width: '100%'}} >
                    <div className="panel-content">
                        {tabContent[1]}
                    </div>
                </TabPanel>
                <TabPanel style={{width: '100%'}}>
                    <div className="panel-content">
                        {tabContent[2]}
                    </div>
                </TabPanel>
                <TabPanel style={{width: '100%'}}>
                    <div className="panel-content">
                        {tabContent[3]}
                    </div>
                </TabPanel>
                <TabPanel style={{width: '100%'}}>
                    <div className="panel-content">
                        {tabContent[4]}
                    </div>
                </TabPanel>
            </Tabs>

        </div>
    );
}

export default TabPage;